<template>
  <div class="stock-daily-updates__wrapper opened-bracket closed-bracket">
    <p class="sidebar__section-title title">Stock Daily Updates</p>
    <ul class="stock-daily-updates__stocks-list sidebar__section-content">
      <li
        v-for="{ Symbol } in stocks"
        :key="Symbol"
        class="stock-daily-updates__stock"
      >
        <a-link :to="getStockLink(Symbol)">{{ Symbol }} stock</a-link>
      </li>
    </ul>
  </div>
</template>

<script>
import ALink from 'shared/ALink'
import { serverCacheKey } from '@/utils/mixins/serverCacheKey'
import mixins from '@/utils/mixins'

export default {
  name: 'StockDailyUpdates',
  // TODO - as soon as we make the stocks list configurable through BO, we have to update the last-modify prop value
  mixins: [serverCacheKey, mixins.urlGenerators],
  components: { ALink },
  data() {
    return {
      stocks: [
        { Symbol: 'AAPL' },
        { Symbol: 'AMD' },
        { Symbol: 'AMZN' },
        { Symbol: 'AXON' }
      ]
    }
  },
  methods: {
    getStockLink(symbol) {
      return this.generateStockRoute(symbol)
    }
  }
}
</script>

<style lang="scss">
.stock-daily-updates__wrapper {
  width: 100%;

  .stock-daily-updates__title {
    padding-left: 29px;
  }

  .stock-daily-updates__stocks-list {
    margin-top: 8px;

    @include sidebar-small-size {
      margin-top: 0;
    }
  }

  .stock-daily-updates__stock {
    overflow: hidden;
    font-family: $font-body;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    padding: 10px;
    border-bottom: 1px solid $c--gray-secondary;

    &:last-child {
      border-bottom: none;
    }
  }
}
</style>
